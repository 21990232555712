<template>
  <div>
    <MyHeader />
    <main id="content">
      <div class="container">
        <br class="mb-5">
        <div class="row align-items-center justify-content-center">
          <div class="col-12">
            <p>1. Cancelación de reservas: Bajo cuales criterios un usuario podrá cancelar su reserva? ¿Política de devolución? Monto mínimo. Retener? Etc.? .</p>
            <p><b>Temporada Baja</b></p>
            <p>Desde el 1 Mayo hasta el 30 de Noviembre. ( No incluye Thanksgiving )* Ver Pick Season</p>
            <p>Política de Cancelación Flexible</p>
            <p>Si se cancelación se hace 3 días antes de la llegada, se hará el reembolso total ( menos las comisiones por servicio)</p>
            <p>Si se cancelación se hace dentro de los 3 días previos a la llegada , la primera noche no será reembolsable. Sin embargo, se reembolsará el 50% del costo por las noches restantes. (más las comisiones por servicio)</p>
            <p><b>Temporada Media</b></p>
            <p>Desde Enero 1 al 1 de Mayo (No incluye Easter) * Ver Pick Season</p>
            <p>Política de cancelación Moderada</p>
            <p>Si la cancelación se hace dentro de un plazo de 48 horas después de la reservación y 14 días antes de la llegada se hará el reembolso total (menos las comisiones por servicio)</p>
            <p>Si la cancelación se hace hasta 7 días antes de la llegada el reembolso será del 50 % (menos las comisiones por servicio).</p>
            <p>Si la cancelación se hace dentro de los 7 días antes de la llegada no habrá ningún tipo de Reembolso.</p>
            <p>Pick Season Dates</p>
            <p>Semana Santa del 15 de Marzo al 15 de Abril.</p>
            <p>Día de acción de gracias 15 de Noviembre al 18 de Diciembre Noche buena del 18 de Diciembre al 25 de Diciembre</p>
            <p>Año Nuevo del 26 de Diciembre al 8 de Enero</p>
            <p>Política de cancelación Estricta</p>
            <p>Si la cancelación se hace 60 días antes de la llegada se hará el reembolso del 100 % (menos la comisión por servicio)</p>
            <p>Si la cancelación se hace 30 días antes de la llegada se hará el reembolso del 50% ( menos la comisión por servicio)</p>
            <p>Si la cancelación se hace en un periodo menor a 30 días no habrá ningún tipo de reembolso.</p>
            <p><b>POLITICA DE PAGO PARA CLIENTES (A discutir )</b></p>
            <p><b>OPCION 1</b></p>
            <p>Si la reserva es realizada para una fecha mayor a 60 días al momento de realizar la reserva el cliente deberá pagar el 50 % al momento de realizar la reserva y el 50 % restante 30 días después de hacer el primer pago. (Únicamente aplica para temporada Baja y media)</p>
            <p><b>OPCION 2</b></p>
            <p>Toda reserva debe ser cancelada en su totalidad al momento de la reservación.</p>
            <p><b>POLITICA DE PAGO PARA LOS PROPIETARIOS</b></p>
            <p>Se le pagara a los propietarios mensualmente y el pago se realizara en los 10 primeros días de cada mes del mes siguiente al corte facturado.</p>
            <p>2. Home be like que cancele una reservación en su propiedad?</p>
            <p>En teoría no se debe pero podríamos dejarlo como back up un descargo de responsabilidad</p>
            <p>Entiendo que no debería permitirse, pero en un caso excepcional, Un propietario podrá solicitar una cancelación pero con un periodo mínimo de anticipación de 90 días (a estipular por junta directiva) y pagando una penalidad correspondiente a la totalidad de la renta realizada en el tiempo que se solicitó y a la tarifa de Temporada Alta. Firmando un descargo donde libera a HBL de cualquier responsabilidad legal que pueda aparecer en caso que el Cliente entable acciones legales por la cancelación.</p>
            <p>3. ¿Tienen definida la información que va en el mapa de que esta cerca de la propiedad?</p>
            <p>Si se refiere a propiedad en el mapa deberá reflejar las propiedades que manejen los filtros a fin con la solicitud del cliente con el fin de darle mas opciones dentro del mismo maco.</p>
            <p>Si se refiere a la zona donde esta la propiedad y esta en búsqueda de experiencias deberá aparecer todo lo relacionado con restaurantes y sitios de interés con el website respectivo (si se tiene )</p>
            <p>
              4. ¿Cuáles son los vacations rentals que se sincronizaran con Home be Like? Airbnb
              Luxury Retreats Home Away VrBO
            </p>
            <p>Rent by Owner</p>
          </div>
        </div>
        <br>
      </div>
    </main>
    <MyFooter />
  </div>
</template>

<script>
export default {
  name: 'CancelationPolicies',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Cancellation Policies',
    // all titles will be injected into this template
    // titleTemplate: '%s | Exclusive experience specialists',
    meta: [
      { name: 'description', content: 'You need to change your plans? We\'ve got you covered.' },
      // Schema.org markup for Google+
      { itemprop: 'name', content: 'Cancellation Policies | CapCana Rentals' },
      { itemprop: 'description', content: 'You need to change your plans? We\'ve got you covered.' },
      { itemprop: 'image', content: 'https://homebelike.com/img/img-homebelike.jpg' },
      // Twitter Card data
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'Cancellation Policies | CapCana Rentals' },
      { name: 'twitter:description', content: 'You need to change your plans? We\'ve got you covered.' },
      // Twitter summary card with large image must be at least 280x150px
      { name: 'twitter:image:src', content: 'https://homebelike.com/img/img-homebelike.jpg' },
      // Open Graph data
      { property: 'og:title', content: 'Cancellation Policies | CapCana Rentals' },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://homebelike.com/cancellation-policies' },
      { property: 'og:image', content: 'https://homebelike.com/img/img-homebelike.jpg' },
      { property: 'og:description', content: 'You need to change your plans? We\'ve got you covered.' },
    ]
  },
};
</script>

<style scoped>
</style>
